@media only screen and (max-width: 600px) {
    header video {
        height: 240px;
        width: 100%;
        object-fit: cover;
    }
}
@media only screen and (min-width: 600px) {
    header video {
        height: 240px;
        width: 100%;
        object-fit: cover;
    }
}
@media only screen and (min-width: 768px) {
    header video {
        height: 240px;
        width: 100%;
    }
}
@media only screen and (min-width: 992px) {
    header video {
        height: 240px;
        width: 100%;
        object-fit: cover;
    }
}
@media only screen and (min-width: 1200px) {
    header video {
        height: 240px;
        width: 100%;
        object-fit: cover;
    }
}

header {
    position: relative;
    text-align: center;
    color: white;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

header .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

header .title div {
    font-size: 42px;
    font-weight: bolder;
}

header .title p {
    font-size: 24px;
    font-weight: bolder;
}

