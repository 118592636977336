@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px) {}

.home-container {
   max-width: 800px;
   margin-left: auto;
   margin-right: auto;
   /* border-left: 1px black solid;
   border-right: 1px black solid; */
   margin-bottom: 100px;
   margin-top: -4px;
}

/* DropzoneComponent css */
.dropzone {
   border: 2px dashed #333;
   border-radius: 5px;
   padding: 20px;
   text-align: center;
   cursor: pointer;
   transition: border 0.2s;
   max-width: 300px;
   height: 100px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 50px;
   display: flex;
   align-items: center;
}

.dropzone p {
   margin: 0;
   color: #333;
}

.dropzone:hover {
   border-color: #333;
}

progress {
   width: 100%;
   margin-top: 10px;
   appearance: none;
   /* Remove default styles */
}

progress::-webkit-progress-bar {
   background-color: #f3f3f3;
   border: solid black 1px;
   /* Light gray background */
   /* border-radius: 12px; */
   /* Rounded corners */
   height: 20px;
   /* Height of the progress bar */
}

progress::-webkit-progress-value {
   background-color: #4caf50;
   /* border: solid black 1px; */
   /* Green progress */
   /* border-radius: 12px; */
   /* Rounded corners */
}

progress::-moz-progress-bar {
   background-color: #4caf50;
   /* border: solid black 1px; */
   /* Green progress */
   /* border-radius: 12px; */
   /* Rounded corners */
   height: 20px;
   /* Height of the progress bar */
}

progress {
   color: #4caf50;
   /* Progress color */
   /* border-radius: 12px; */
   /* Rounded corners */
   height: 20px;
   /* Height of the progress bar */
}

.filename-container {
   width: 300px;
   max-width: 300px;
   margin-left: auto;
   margin-right: auto;
}
.filename-container button {
   background-color: #4caf4f;
   /* Green background */
   border: none;
   /* Remove default border */
   color: white;
   /* White text */
   padding: 12px 24px;
   /* Padding around the text */
   font-size: 16px;
   /* Increase font size */
   font-weight: bold;
   /* Bold text */
   border-radius: 8px;
   /* Rounded corners */
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
   /* Subtle shadow */
   cursor: pointer;
   /* Pointer cursor on hover */
   transition: background-color 0.3s ease, transform 0.2s ease;
   /* Smooth hover effect */
   margin-top: 10px;
}

.filename-container button:hover {
   background-color: #45a049;
   /* Darker green on hover */
   transform: translateY(-2px);
   /* Slight lift effect on hover */
}

.filename-container button:active {
   background-color: #3e8e41;
   /* Even darker green when clicked */
   transform: translateY(0);
   /* Reset the lift effect */
}

.filename-container button:focus {
   outline: none;
   /* Remove the default focus outline */
   box-shadow: 0 0 0 3px rgba(72, 189, 117, 0.4);
   /* Custom focus shadow */
}

.dropzone-response {
   max-width: 300px;
   width: 300px;
   margin-right: auto;
   margin-left: auto;
}

.hero {
   text-align: center;
   background-color: #333;
   color: white;
   padding: 50px 20px;
}

.hero h1 {
   font-size: 48px;
   margin-bottom: 20px;
}

.hero p {
   font-size: 18px;
}

.cta-button {
   background-color: #f39c12;
   color: white;
   padding: 15px 30px;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   font-size: 16px;
   transition: background-color 0.3s;
   margin-top: 10px;
}

.cta-button:hover {
   background-color: #e67e22;
}

.about, .features, .cta {
   border: solid 1px black;
   text-align: center;
   padding: 40px 20px;
}

.about h2, .features h2, .cta h2 {
   font-size: 32px;
   margin-bottom: 20px;
}

.about p, .cta p {
   font-size: 18px;
   max-width: 800px;
   margin: 0 auto;
}

.features ul {
   list-style-type: none;
   padding: 0;
}

.features li {
   font-size: 18px;
   margin: 10px 0;
}