@media only screen and (max-width: 600px) {
    
}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 768px) {
    
}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {}

footer {
    position: relative;
    bottom: 0;
    left: 0;
    margin-bottom: 100px;
    
    height: 140px;
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
    border-top: solid 1px black;

}